import { IconX } from "@tabler/icons-react";
import { commonOpenModal } from "@/components/common/modals";
import { inviteCollaboratorModalId } from "@/data/modal";
import InviteCollaboratorContainer from "@/components/collaborator/InviteCollaboratorContainer";
import MetaText from "@/components/textComponent/MetaText";

export const openInviteCollaborator = ({
  inviteCollaboratorOpened,
  inviteCollaboratorModalClose,
}: {
  inviteCollaboratorOpened: boolean;
  inviteCollaboratorModalClose: () => void;
}) => {
  commonOpenModal({
    modalId: inviteCollaboratorModalId,
    radius: "lg",
    opened: inviteCollaboratorOpened,
    onClose: inviteCollaboratorModalClose,
    closeButtonProps: { icon: <IconX color="#e92c2c" size="15px" /> },
    title: (
      <MetaText px="md" c="#009379">
        Who would you like to plan your wedding with?
      </MetaText>
    ),
    style: { borderRadius: "40px" },
    size: "lg",
    styles: { inner: { marginTop: 90 } },
    children: (
      <InviteCollaboratorContainer onClose={inviteCollaboratorModalClose} />
    ),
    centered: false,
  });
};

import {
  InputActionMeta,
  MultiValue,
  SingleValue,
  components,
} from "react-select";
import {
  Button,
  Divider,
  Text,
  Group,
  Avatar,
  Box,
  Select,
  ActionIcon,
  Stack,
  Badge,
} from "@mantine/core";
import { getQueryClient } from "@/api";
import { Fragment, useState, useCallback } from "react";
import { CustomSelectOption } from "@/types/common";
import { useApi } from "@/hooks/useApi";
import { UserRoleEnum } from "contract/enum";
import { IconChevronDown, IconX } from "@tabler/icons-react";
import { OptionProps } from "react-select";
import { validateEmail } from "@/utils/validation";
import AsyncSearchSelect from "@/components/form/AsyncSearchSelect";
import { useUserData } from "@/contexts/UserProvider";
import { getInitialsForAvatar } from "@/root/utils";
import { useScreenBreakpoints } from "@/contexts/ScreenBreakpointsProvider";
import isNil from "lodash/isNil";
import MetaText from "@/components/textComponent/MetaText";
import { SelectedUser, CustomSelectOptionWithUser } from "@/types/invite";

const baseRoles = [
  { value: UserRoleEnum.collaborator, label: "Collaborator" },
  { value: UserRoleEnum.planner, label: "Planner" },
  { value: UserRoleEnum.spouse, label: "Spouse" },
];

export const SelectedUserRow = ({
  user,
  onRoleChange,
  onRemove,
}: {
  user: SelectedUser;
  onRoleChange: (role: UserRoleEnum) => void;
  onRemove: (user: SelectedUser) => void;
}) => {
  const label = user.name ? `${user.name} (${user.email})` : user.email;
  const { isMobile, isTablet } = useScreenBreakpoints();
  const isMobileOrTablet = isMobile || isTablet;
  const data = user.isProPlanner
    ? [...baseRoles, { value: UserRoleEnum.proPlanner, label: "Pro Planner" }]
    : baseRoles;
  return (
    <Group
      gap={isMobileOrTablet ? 0 : "xs"}
      wrap={isMobileOrTablet ? "wrap" : "nowrap"}
      justify={"space-between"}
    >
      <Group gap="xs" wrap="nowrap">
        <Avatar size={30} src={user.profilePhotoUrl} alt={user.name} />
        <Text fw={500} flex={1}>
          {label}
        </Text>
      </Group>
      <Group
        gap="xs"
        wrap="nowrap"
        justify={"space-between"}
        w={isMobileOrTablet ? "100%" : "max-content"}
      >
        <Select
          checkIconPosition="right"
          styles={{
            root: {
              maxWidth: "135px",
            },
            wrapper: {
              width: "135px",
            },
            input: {
              border: "none",
            },
          }}
          rightSection={<IconChevronDown color="#17B898" size="16px" />}
          name="role"
          placeholder="Select role"
          style={{ width: "200px" }}
          value={user.role}
          onChange={(role) => onRoleChange(role as UserRoleEnum)}
          data={data}
          readOnly={user.isProPlanner}
        />
        <ActionIcon onClick={() => onRemove(user)} bg="transparent">
          <IconX color="#e92c2c" size="15px" />
        </ActionIcon>
      </Group>
    </Group>
  );
};

export const CustomOption = (
  props: OptionProps<CustomSelectOptionWithUser>
) => {
  const isNew = props.data.__isNew__ === true;
  const value = props.data.value;

  if (isNew) {
    if (!validateEmail(value)) {
      return null;
    }
    return (
      <components.Option {...props}>
        <Text tt="initial">
          Invite <span style={{ fontWeight: "500" }}>"{value}"</span>
        </Text>
      </components.Option>
    );
  }

  const user = props.data.user;

  if (!user) {
    return null; // this case won't happen
  }

  return (
    <components.Option {...props}>
      <Group gap={"xs"} wrap="nowrap">
        <Avatar size="sm" src={user.profilePhotoUrl} alt={user.name}>
          {getInitialsForAvatar(user.name)}
        </Avatar>
        <Text
          fw={500}
          flex={1}
          style={{ fontSize: "14px", textTransform: "lowercase" }}
        >
          {user.name} ({user.email})
        </Text>
        {user.isProPlanner && (
          <Badge color="var(--mantine-color-primaryGreen-3)" size="xs">
            Pro Planner
          </Badge>
        )}
      </Group>
    </components.Option>
  );
};

const InviteCollaboratorContainer = ({ onClose }: { onClose: () => void }) => {
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [currentInputValue, setCurrentInputValue] = useState("");
  const { makeApiCall } = useApi();
  const { userDetails } = useUserData();
  const handleSubmit = () => {
    const currentProjectId = userDetails.currentProject.id;
    if (isNil(currentProjectId)) {
      return;
    }
    makeApiCall({
      fetcherFn: async () => {
        const response = await getQueryClient().wedding.inviteMember.mutation({
          body: {
            projectId: currentProjectId,
            invitedUsers: selectedUsers,
          },
        });
        return response;
      },
      successMsgProps: { message: "Invitations sent successfully." },
      onSuccessFn: () => {
        setSelectedUsers([]);
        onClose();
      },
      showLoader: true,
    });
  };
  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (
      actionMeta.action !== "input-blur" &&
      actionMeta.action !== "menu-close"
    ) {
      setCurrentInputValue(newValue);
    }
  };

  const handleInviteClick = (currentInputValue: string) => {
    // Create a new option with the current input value
    const newOption = {
      email: currentInputValue.toLowerCase(),
      role: UserRoleEnum.collaborator,
      isProPlanner: false,
    };

    setSelectedUsers((prevOptions) => [...prevOptions, newOption]);
    setCurrentInputValue("");
  };
  const onChange = useCallback(
    (
      o:
        | SingleValue<CustomSelectOption>
        | MultiValue<CustomSelectOptionWithUser>
    ) => {
      const value = o as MultiValue<CustomSelectOptionWithUser>;
      setSelectedUsers((prevSelectedUsers) =>
        value.map((v) => {
          const existingUser = prevSelectedUsers.find(
            (user) =>
              user.email.toLowerCase() === v.value.toLowerCase() ||
              user.email.toLowerCase() === v.user?.email?.toLowerCase()
          );
          if (v.user) {
            return {
              id: v.user.id,
              email: v.user.email.toLowerCase(),
              name: v.user.name,
              role: v.user.isProPlanner
                ? UserRoleEnum.proPlanner
                : existingUser
                  ? existingUser.role
                  : UserRoleEnum.collaborator,
              profilePhotoUrl: v.user.profilePhotoUrl,
              isProPlanner: v.user.isProPlanner,
            };
          }
          return {
            email: v.value.toLowerCase(),
            role: existingUser ? existingUser.role : UserRoleEnum.collaborator,
            isProPlanner: existingUser ? existingUser.isProPlanner : false,
          };
        })
      );
    },
    [selectedUsers]
  );

  return (
    <Box px="md">
      <Divider />
      <MetaText mt="sm" mb="sm">
        Invite Collaborators
      </MetaText>
      <Group>
        <Box flex={1}>
          <AsyncSearchSelect
            customStyles={{
              control: (styles) => ({
                ...styles,
                paddingBlock: "0px",
                paddingInline: "10px",
                borderRadius: "20px",
                maxHeight: "35px",
                fontSize: "14px",
              }),
              placeholder: (styles) => ({
                ...styles,
                fontSize: "12px",
                fontStyle: "italic",
                color: "#3F3F3FB2",
              }),
              option: (styles) => ({
                ...styles,
                fontSize: "14px",
              }),
            }}
            name="collaborators"
            placeholder="Enter name or email ID"
            value={selectedUsers.map((u) => ({
              value: u.email,
              label: u.name + " (" + u.email + ")",
            }))}
            isMulti
            controlShouldRenderValue={false}
            customComponents={
              {
                Option: CustomOption,
              } as any
            } // Todo: Find a better way to do this, ideally we have to make asyncsearchselect generic with optiontype extends customselectoption, but not sure how to do it since it uses forwardref
            showDropdownOnModal
            instanceId="add-collaborator-input"
            onChange={onChange}
            onInputChange={handleInputChange}
            onMultipleChange={onChange}
            getOptions={async (val: string) => {
              const currentProjectId = userDetails.currentProject.id;
              if (isNil(currentProjectId)) {
                return [];
              }
              const data =
                await getQueryClient().wedding.searchUsersToInvite.query({
                  query: {
                    projectId: currentProjectId,
                    searchText: val,
                  },
                });
              if (data.status === 200) {
                if (data.body.length === 0 && validateEmail(val)) {
                  return [
                    {
                      value: val.toLowerCase(),
                      label: val.toLowerCase(),
                      __isNew__: true,
                    },
                  ];
                }

                const valExists = data.body.some(
                  (eachVal) => eachVal.email === val
                );

                // Map over data.body and add the new object if val is a valid email and doesn't exist in data.body
                return [
                  ...data.body.map((eachVal) => {
                    return {
                      value: eachVal.email,
                      label: eachVal.name,
                      user: {
                        ...eachVal,
                        role: UserRoleEnum.collaborator,
                      },
                    };
                  }),
                  ...(!valExists && validateEmail(val)
                    ? [
                        {
                          value: val,
                          label: val,
                          __isNew__: true,
                        },
                      ]
                    : []),
                ];
              }

              return [];
            }}
          />
        </Box>
        <Box ta="center" display={{ base: "none", sm: "block" }}>
          <Button
            py={10}
            color="#17B898"
            style={{ borderRadius: "30px" }}
            maw="max-content"
            disabled={
              currentInputValue === "" || !validateEmail(currentInputValue)
            }
            onClick={() => {
              handleInviteClick(currentInputValue);
            }}
          >
            Invite
          </Button>
        </Box>
      </Group>
      <Stack gap="sm" mt="lg" mb="md" mah={220} style={{ overflow: "scroll" }}>
        {selectedUsers.map((u, index) => (
          <Fragment key={`selectedUsers-${index}`}>
            <SelectedUserRow
              user={u}
              onRoleChange={(role) => {
                setSelectedUsers((prevSelectedUsers) =>
                  prevSelectedUsers.map((o) => {
                    if (u.email === o.email) {
                      return {
                        ...o,
                        role,
                      };
                    }
                    return o;
                  })
                );
              }}
              onRemove={(u) =>
                setSelectedUsers(
                  selectedUsers.filter((o) => o.email !== u.email)
                )
              }
            />
          </Fragment>
        ))}
      </Stack>
      <Box ta="center" mt={80} mb="md">
        <Button
          px={40}
          color="#F28482"
          style={{ borderRadius: "30px" }}
          onClick={handleSubmit}
          disabled={selectedUsers.length === 0}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default InviteCollaboratorContainer;

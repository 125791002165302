// Using image kit loader temporarily since our own image compress server is very slow
const urls = [
  "https://uthsav-themes-stage.s3.ap-south-1.amazonaws.com",
  "https://uthsav-themes-prod.s3.ap-south-1.amazonaws.com",
];
const imageKitLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality: number;
}) => {
  // return src;
  if (src[0] === "/") src = src.slice(1);
  const params = [`w-${width}`];
  if (quality) {
    params.push(`q-${quality}`);
  }
  const paramsString = params.join(",");
  var urlEndpoint = "https://ik.imagekit.io/jzq3bhqlc";
  if (urlEndpoint[urlEndpoint.length - 1] === "/")
    urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1);
  const urlToReplace = urls.find((url) => src.includes(url));

  if (urlToReplace) {
    src = src.replace(urlToReplace, "");
  }
  return `${urlEndpoint}/${src}?tr=${paramsString}`;
};

export default imageKitLoader;

// export default function customImageLoader({
//   src,
//   width,
//   quality,
// }: {
//   src: string;
//   width: number;
//   quality: number;
// }) {
//   return `https://image-compress.uthsav.com/_next/image?url=${src}&w=${width}&q=${
//     quality || 75
//   }`;
// }

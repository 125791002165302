import {
  Button,
  Box,
  Group,
  Divider,
  Modal,
  Flex,
  Stack,
  ModalProps,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import React from "react";
import Link from "next/link";
import classes from "@/styles/button.module.css";
import SectionSubTitle from "@/components/textComponent/SectionSubTitle";
import MetaText from "@/components/textComponent/MetaText";

export const openSuccessModal = ({
  header,
  message,
  icon,
}: {
  header: string;
  message: string;
  icon: React.ReactNode;
}) => {
  modals.open({
    children: (
      <Stack
        justify="space-around"
        align="center"
        ta="center"
        px={"md"}
        my="lg"
      >
        {icon}
        <SectionSubTitle
          m="auto"
          mt="sm"
          ta="center"
          maw={{ base: "150px", sm: "1000px" }}
        >
          {header}
        </SectionSubTitle>
        <MetaText m={0}>{message}</MetaText>
        <Button
          mb={"lg"}
          w="100%"
          size="lg"
          style={{ borderRadius: "30px" }}
          color="#F6BD60"
          tt="uppercase"
          onClick={() => modals.closeAll()}
        >
          Ok
        </Button>
      </Stack>
    ),
  });
};

export const DiscardChangesModal = ({
  isOpen,
  onClose,
  onContinue,
}: {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}) => {
  return (
    <Modal
      title={<SectionSubTitle px="md">Discard Changes</SectionSubTitle>}
      size="lg"
      zIndex={1000} // this modal should come above other modals, so increasing the zindex
      opened={isOpen}
      onClose={onClose}
      styles={{ content: { borderRadius: 20 } }}
    >
      <Box px="md">
        <Divider />
        <Box my="lg">
          <MetaText>Are you sure you want to discard your changes?</MetaText>
          <Group justify="right" my={"lg"}>
            <Button
              ff="Inter"
              fw="400"
              radius="100px"
              variant="outline"
              color="#17B898"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              ff="Inter"
              fw="400"
              radius="100px"
              color="#17B898"
              onClick={onContinue}
            >
              Discard
            </Button>
          </Group>
        </Box>
      </Box>
    </Modal>
  );
};

export const openFormModal = ({
  header,
  formComponent,
  modalId,
}: {
  header?: string;
  formComponent: React.ReactNode;
  modalId: string;
}) => {
  modals.open({
    modalId: modalId,
    title: <SectionSubTitle>{header}</SectionSubTitle>,
    centered: true,
    children: formComponent,
    closeButtonProps: { iconSize: 32, color: "#000000" },
    styles: {
      root: { padding: 0, width: "100%", paddingBlock: 20, maxWidth: 560 },
      header: { padding: 0 },
      body: { padding: 0, marginBottom: 30 },
      content: { padding: 0, paddingInline: 24, borderRadius: 12 },
    },
  });
};

export const openDeleteAccountConfirmModal = ({
  header,
  formComponent,
  modalId,
}: {
  header?: string;
  formComponent: React.ReactNode;
  modalId: string;
}) => {
  modals.open({
    modalId: modalId,
    title: header,
    size: "auto",
    radius: "md",
    withCloseButton: false,
    children: formComponent,
    centered: true,
  });
};

export const openDeleteAccountPermanentlyModal = ({
  header,
  formComponent,
  modalId,
}: {
  header?: string;
  formComponent: React.ReactNode;
  modalId: string;
}) => {
  modals.open({
    modalId: modalId,
    title: <SectionSubTitle>{header}</SectionSubTitle>,
    centered: true,
    children: formComponent,
    closeButtonProps: { iconSize: 32, color: "#000000" },
    styles: {
      root: {
        padding: 0,
        maxWidth: 680,
      },
      content: { paddingInline: 12, borderRadius: 12 },
    },
  });
};

export const commonOpenModal = ({
  children,
  modalId,
  key,
  ...props
}: {
  header?: string;
  children: React.ReactNode;
  modalId: string;
  key?: string;
} & ModalProps) => {
  modals.open({
    key: key,
    modalId: modalId,
    centered: true,
    children: children,
    ...props,
  });
};

export const openConfirmationModal = ({
  modalId,
  header,
  message,
  onCancel,
  onConfirm,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
}: {
  modalId: string;
  header: string;
  message: React.ReactNode;
  onCancel?: () => void;
  onConfirm: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}) => {
  modals.openConfirmModal({
    modalId,
    title: <SectionSubTitle>{header}</SectionSubTitle>,
    centered: true,
    size: "md",
    children: <MetaText py={20}>{message}</MetaText>,
    closeButtonProps: { iconSize: 32, color: "#000000" },
    styles: {
      root: { padding: 0, width: "100%", paddingBlock: 20, maxWidth: 560 },
      header: { padding: 0 },
      body: { padding: 0, marginBottom: 30 },
      content: { padding: 0, paddingInline: 24, borderRadius: 12 },
    },
    labels: { confirm: confirmLabel, cancel: cancelLabel },
    onCancel: () => {
      onCancel ? onCancel() : modals.close(modalId);
    },
    onConfirm: onConfirm,
    confirmProps: {
      ff: "Inter",
      fw: "400",
      radius: "100px",
      color: "primaryPink.2",
    },
    cancelProps: {
      ff: "Inter",
      fw: "400",
      radius: "100px",
    },
  });
};

export const pageRestrictionModal = ({ modalId }: { modalId: string }) => {
  modals.open({
    modalId,
    centered: true,
    size: "lg",
    styles: { content: { padding: 10, borderRadius: 20 } },
    title: <SectionSubTitle>Oops! You can't view this page!</SectionSubTitle>,
    children: (
      <Stack justify="center" gap={20}>
        <Divider h="0.5px" style={{ bg: "#BCBCBC" }} />
        <MetaText w="100%">
          Sorry! You don’t have permission to access this page. To change
          permissions, contact the wedding project owner.
        </MetaText>
        <Flex
          direction={{ base: "column-reverse", sm: "row" }}
          justify={{ base: "center", sm: "flex-end" }}
          gap={10}
          mt={10}
        >
          <Button
            w={{ base: "100%", sm: "max-content" }}
            style={{ borderRadius: "50px", border: "1px solid #17B898" }}
            bg="white"
            c="#17B898"
            fz={{ base: 14, sm: 16 }}
            fw={600}
            lh="1.48"
            ff="inter"
            onClick={() => modals.close(modalId)}
            className={classes.outlineButton}
          >
            Cancel
          </Button>

          <Link href="/timeline">
            <Button
              w={{ base: "100%", sm: "max-content" }}
              style={{ borderRadius: "50px" }}
              bg="#17B898"
              fz={{ base: 14, sm: 16 }}
              fw={600}
              lh="1.48"
              ff="inter"
              c="white"
              className={classes.greenButton}
              onClick={() => modals.close(modalId)}
            >
              Go To Timeline
            </Button>
          </Link>
        </Flex>
      </Stack>
    ),
  });
};

export const openRegistrationModal = ({
  children,
  modalId,
}: {
  children: React.ReactNode;
  modalId: string;
}) => {
  modals.open({
    modalId: modalId,
    centered: true,
    title: <></>,
    children: children,
    size: "100%",
    styles: {
      root: {
        padding: 0,
        width: "100%",
        paddingBlock: 20,
      },
      header: { display: "none" },
      body: { padding: 0, marginBottom: 0 },
      content: {
        padding: 0,
        paddingInline: 0,
        borderRadius: 25,
        maxWidth: 1600,
      },
    },
  });
};

import { Loader, Center } from "@mantine/core";

const Loading = () => {
  return (
    <Center>
      <Loader color="#F28482" type="dots" />
    </Center>
  );
};

export default Loading;

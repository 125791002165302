export interface BreadcrumbsInterface {
  title: React.ReactNode;
  href: string;
}

export enum ThemeVariant {
  LIGHT = "light",
  DARK = "dark",
}

export type NavMenuItemType = {
  href: string;
  label: string;
  subText: string;
  navigationItem: NavigationItemType;
};

export enum NavigationItemType {
  themes = "themes",
  timeline = "timeline",
  budget = "budget",
  browseVendors = "browseVendors",
  myEnquiries = "myEnquiries",
}

export interface CommonHeaderMenuItem {
  link: string;
  text: string;
  onClick?: () => void;
}

export interface DropdownMenuProps {
  buttonText: string;
  menuItems: CommonHeaderMenuItem[];
}

import Link from "next/link";
import classes from "@/styles/link.module.css";
import { Text, TextProps } from "@mantine/core";

const UnStyledTextLink = ({
  label,
  href,
  ...props
}: { label: string; href: string } & TextProps) => {
  return (
    <Link href={href}>
      <Text
        className={classes.customLink}
        ff="Inter"
        fw={700}
        c="white"
        w="100%"
        fz={18}
        {...props}
      >
        {label}
      </Text>
    </Link>
  );
};
export default UnStyledTextLink;

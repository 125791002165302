import { Box, BoxProps } from "@mantine/core";
import isNil from "lodash/isNil";
import Image from "next/image";
import { CSSProperties } from "react";

const CustomNextImage = ({
  imageSrc,
  imageAlt,
  imageWidth,
  imageHeight,
  imageWrapperProps,
  imageStyle,
  imageResponsiveSize,
  isHighPriority,
  isFill = true,
  noWrapper = false,
}: {
  imageSrc: string;
  imageAlt?: string;
  imageWidth?: number;
  imageHeight?: number;
  imageWrapperProps?: BoxProps;
  imageStyle?: CSSProperties;
  imageResponsiveSize?: string;
  isHighPriority?: boolean;
  isFill?: boolean;
  noWrapper?: boolean;
}) => {
  const image = (
    <Image
      {...(!isNil(imageWidth) && !isFill ? { width: imageWidth } : {})}
      {...(!isNil(imageHeight) && !isFill ? { height: imageHeight } : {})}
      sizes={imageResponsiveSize ?? "(max-width: 768px) 100vw, 30vw"}
      style={
        imageStyle ?? {
          objectFit: "cover",
          overflow: "hidden",
          borderRadius: "8px",
        }
      }
      alt={imageAlt ?? "image"}
      fill={isFill}
      priority={isHighPriority}
      src={imageSrc}
    />
  );

  if (noWrapper) {
    return image;
  }

  return (
    <Box pos="relative" {...imageWrapperProps}>
      {image}
    </Box>
  );
};

export default CustomNextImage;
